@tailwind base;
@tailwind components;
@tailwind utilities;

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&family=Inter:wght@100..900&display=swap');

@font-face {
	font-family: 'Desmontilles';
	src: url('/fonts/desmontilles-regular.woff2') format('woff2'),
		url('/fonts/desmontilles-regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Duke';
	src: url('/fonts/duke.woff2') format('woff2'),
		url('/fonts/duke.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

html {
	scroll-behavior: smooth;
}

body {
	@apply bg-yt-off-white;
	@apply font-inter-tight antialiased;
}

svg.icon-svg path {
	fill: currentColor;
}

svg.icon-svg-stroke path {
	stroke: currentColor;
}

.swiper-pagination {
	&.ys-main-swiper-pagination {
		.swiper-pagination-bullet {
			@apply bg-yt-green opacity-25 w-[15px] h-[15px] bottom-0;
			margin: 0 8px !important;

			&.swiper-pagination-bullet-active {
				@apply bg-yt-green opacity-100;
			}
		}
	}
}

svg.slider-straight-arrow-left,
svg.slider-straight-arrow-right {
	path {
		stroke: #25282b;
	}
}

svg.slider-straight-arrow-left:hover,
svg.slider-straight-arrow-right:hover {
	opacity: 0.33;
}

.btn-plan {
	svg.text-yt-green {
		@apply block;
	}

	> span {
		@apply text-yt-off-white relative z-10;
	}
}

// .vertical-slider {
//     display: flex;
//     width: 100%;
// }

// .desktop-only {
//     display: none !important;
// }

@media (min-width: 1024px) {
	.desktop-only {
		display: flex !important;
	}

	.ys-vertical-slider-scroll > * {
		width: 50%;
	}

	.ys-vertical-slider-scroll-sticky {
		position: sticky;
		top: 0;
		height: 100%;
		max-height: 100vh;
	}

	.absolute-full {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.ys-vertical-slider-scroll-content-wrapper {
		min-height: 100vh;
		transition: opacity 0.5s ease-out;
	}

	.ys-vertical-slider-scroll-content-wrapper.in-view {
		opacity: 1;
		transition: opacity 0.5s ease-in-out;
	}

	.ys-vertical-slider-scroll-image-wrapper .mask-outer {
		transform: translateY(100%);
		overflow: hidden;
	}

	.ys-vertical-slider-scroll-image-wrapper .mask-inner {
		transform: translateY(-100%);
		opacity: 0;
		transition: opacity 1s ease-in-out;
	}

	.ys-vertical-slider-scroll-image-wrapper.show .mask-outer {
		transform: translateY(0);
		overflow: visible;
	}
	.ys-vertical-slider-scroll-image-wrapper.show .mask-inner {
		display: flex;
		align-items: center;
		transform: translateY(0);
		transition: opacity 1s ease-in-out;
		opacity: 1;
	}
}
